// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { signInWithGoogle, signInWithApple, signUpWithEmail, signInWithEmail } from './firebase';

// const SignInScreen = () => {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [fullName, setFullName] = useState('');
//     const [showEmailForm, setShowEmailForm] = useState(false);
//     const [isSignUp, setIsSignUp] = useState(false);
//     const [alert, setAlert] = useState({ show: false, message: '', type: '' });

//     const showAlert = (message, type) => {
//         setAlert({ show: true, message, type });
//         setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000); // Hide alert after 5 seconds
//     };

//     const handleAuthentication = async (authFunction, ...args) => {
//         try {
//             await authFunction(...args);
//             showAlert('Successfully authenticated!', 'success');
//             navigate('/chat');
//         } catch (error) {
//             showAlert(`Authentication error: ${error.message}`, 'error');
//         }
//     };

//     const handleGoogleSignIn = () => handleAuthentication(signInWithGoogle);
//     const handleAppleSignIn = () => handleAuthentication(signInWithApple);


//     const handleEmailAuth = async (e) => {
//         e.preventDefault();
//         if (isSignUp) {
//             if (password !== confirmPassword) {
//                 showAlert('Passwords don\'t match', 'error');
//                 return;
//             }
//             await handleAuthentication(signUpWithEmail, email, password, fullName);
//         } else {
//             await handleAuthentication(signInWithEmail, email, password);
//         }
//     };


//     return (
//         <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
//             {alert.show && (
//                 <div className={`fixed top-4 right-4 p-4 rounded-md ${alert.type === 'success' ? 'bg-green-500' : 'bg-red-500'
//                     } text-white`}>
//                     {alert.message}
//                 </div>
//             )}
//             <div className="w-full max-w-md">
//                 <h1 className="text-4xl font-bold mb-2">Welcome to</h1>
//                 <h1 className="text-5xl font-bold text-blue-500 mb-8">ARROW.AI</h1>

//                 <button onClick={handleGoogleSignIn} className="w-full bg-white text-black border border-gray-300 rounded-full py-3 mb-4 flex items-center justify-center">
//                     <span className="mr-2">G</span>
//                     Continue with Google
//                 </button>

//                 <button onClick={handleAppleSignIn} className="w-full bg-black text-white rounded-full py-3 mb-4 flex items-center justify-center">
//                     <span className="mr-2">🍎</span>
//                     Continue with Apple
//                 </button>

//                 {!showEmailForm ? (
//                     <button onClick={() => setShowEmailForm(true)} className="w-full bg-gray-800 text-white rounded-full py-3 flex items-center justify-center">
//                         <span className="mr-2">✉️</span>
//                         Continue with email
//                     </button>
//                 ) : (
//                     <form onSubmit={handleEmailAuth} className="w-full">
//                         {isSignUp && (
//                             <input
//                                 type="text"
//                                 placeholder="Full Name"
//                                 value={fullName}
//                                 onChange={(e) => setFullName(e.target.value)}
//                                 className="w-full mb-4 p-2 rounded border"
//                             />
//                         )}
//                         <input
//                             type="email"
//                             placeholder="Email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="w-full mb-4 p-2 rounded border"
//                         />
//                         <input
//                             type="password"
//                             placeholder="Password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="w-full mb-4 p-2 rounded border"
//                         />
//                         {isSignUp && (
//                             <input
//                                 type="password"
//                                 placeholder="Confirm Password"
//                                 value={confirmPassword}
//                                 onChange={(e) => setConfirmPassword(e.target.value)}
//                                 className="w-full mb-4 p-2 rounded border"
//                             />
//                         )}
//                         <button type="submit" className="w-full bg-blue-500 text-white rounded-full py-3 mb-4">
//                             {isSignUp ? 'Sign Up' : 'Sign In'}
//                         </button>
//                         <button type="button" onClick={() => setIsSignUp(!isSignUp)} className="text-blue-500 w-full text-center">
//                             {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
//                         </button>
//                     </form>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default SignInScreen;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { signInWithGoogle, signInWithApple, signUpWithEmail, signInWithEmail } from './firebase';
// import { ThemeProvider } from './ThemeContext';

// const GoogleLogo = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
//       <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
//       <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
//       <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
//       <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
//     </svg>
//   );
  
//   const AppleLogo = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24px" height="24px">
//       <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
//     </svg>
//   );
  

// const SignInScreen = () => {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [fullName, setFullName] = useState('');
//     const [isSignUp, setIsSignUp] = useState(false);
//     const [alert, setAlert] = useState({ show: false, message: '', type: '' });
//     const [isFormValid, setIsFormValid] = useState(false);
    
//     const validateEmail = (email) => {
//         const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//         return re.test(String(email).toLowerCase());
//     };

//     useEffect(() => {
//         if (isSignUp) {
//             setIsFormValid(
//                 validateEmail(email) &&
//                 password.length >= 4 &&
//                 password === confirmPassword &&
//                 fullName.trim() !== ''
//             );
//         } else {
//             setIsFormValid(
//                 validateEmail(email) &&
//                 password.length >= 4
//             );
//         }
//     }, [email, password, confirmPassword, fullName, isSignUp]);


//     const showAlert = (message, type) => {
//         setAlert({ show: true, message, type });
//         setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
//     };

//     const handleAuthentication = async (authFunction, ...args) => {
//         try {
//             await authFunction(...args);
//             showAlert('Successfully authenticated!', 'success');
//             navigate('/chat');
//         } catch (error) {
//             showAlert(`Authentication error: ${error.message}`, 'error');
//         }
//     };

//     const handleGoogleSignIn = () => handleAuthentication(signInWithGoogle);
//     const handleAppleSignIn = () => handleAuthentication(signInWithApple);

//     const handleEmailAuth = async (e) => {
//         e.preventDefault();
//         if (isSignUp) {
//             if (password !== confirmPassword) {
//                 showAlert('Passwords don\'t match', 'error');
//                 return;
//             }
//             await handleAuthentication(signUpWithEmail, email, password, fullName);
//         } else {
//             await handleAuthentication(signInWithEmail, email, password);
//         }
//     };

//     return (
//         <ThemeProvider>
//           <div className="flex items-center justify-center min-h-screen bg-[rgb(229,229,225)] dark:bg-[rgb(36,36,36)] p-4 font-semibold">
//             {alert.show && (
//               <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 p-4 rounded-full ${alert.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white font-bold`}>
//                 {alert.message}
//               </div>
//             )}
//             <div className="w-full max-w-md text-center">
//               <div className="flex items-center justify-center mb-4">
//                 <img 
//                   src="/app_icon.png" 
//                   alt="Arrow.AI Logo" 
//                   className="h-10 w-10 rounded-lg mr-2" 
//                 />
//                 <h1 className="text-4xl font-bold dark:text-gray-200">Arrow.AI</h1>
//               </div>
//               <p className="text-lg mb-8 font-semibold dark:text-gray-300">Access cutting-edge AI from OpenAI, Anthropic, Google, Perplexity, and more (soon).<br></br> Start now for free!</p>
    
//               <button 
//                 onClick={handleGoogleSignIn} 
//                 className="w-full bg-white text-black border border-gray-300 rounded-full py-2 mb-4 flex items-center justify-center font-bold transition-transform duration-200 hover:scale-105"
//               >
//                 <GoogleLogo />
//                 <span className="ml-2">Continue with Google</span>
//               </button>
    
//               <button 
//                 onClick={handleAppleSignIn} 
//                 className="w-full bg-white text-black border border-gray-300 rounded-full py-2 mb-4 flex items-center justify-center font-bold transition-transform duration-200 hover:scale-105"
//               >
//                 <AppleLogo />
//                 <span className="ml-2">Continue with Apple</span>
//               </button>
    
//               <div className="text-center text-gray-500 my-4 font-bold">or</div>
    
//               <form onSubmit={handleEmailAuth} className="w-full">
//                 {isSignUp && (
//                   <input
//                     type="text"
//                     placeholder="Full Name"
//                     value={fullName}
//                     onChange={(e) => setFullName(e.target.value)}
//                     className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
//                   />
//                 )}
//                 <input
//                   type="email"
//                   placeholder="Email address"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
//                 />
//                 <input
//                   type="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
//                 />
//                 {isSignUp && (
//                   <input
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
//                   />
//                 )}
//                 <button 
//                   type="submit" 
//                   className={`w-full ${isFormValid ? 'bg-gray-900 dark:bg-[rgb(71,150,228)] hover:scale-105' : 'bg-gray-500 cursor-not-allowed'} text-white rounded-full py-2 mb-4 font-bold transition-transform duration-200`}
//                   disabled={!isFormValid}
//                 >
//                   {isSignUp ? 'Sign Up' : 'Log In'}
//                 </button>
//               </form>
    
//               <button 
//                 onClick={() => {
//                   setIsSignUp(!isSignUp);
//                   setIsFormValid(false);
//                   setEmail('');
//                   setPassword('');
//                   setConfirmPassword('');
//                   setFullName('');
//                 }} 
//                 className="text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 w-full text-center font-bold transition-colors duration-200"
//               >
//                 {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
//               </button>
    
//               <p className="text-sm text-gray-500 mt-4 text-center font-semibold">
//                 By continuing, you are agreeing to Arrow.AI's Terms of Service and Privacy Policy.
//               </p>
//             </div>
//           </div>
//         </ThemeProvider>
//       );
//     };
    
//     export default SignInScreen;
    

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithApple, signUpWithEmail, signInWithEmail } from './firebase';
import { ThemeProvider } from './ThemeContext';

const GoogleLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
      <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
      <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
      <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
      <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
    </svg>
);
  
const AppleLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24px" height="24px">
      <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
    </svg>
);

const SignInScreen = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [isFormValid, setIsFormValid] = useState(false);
    
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    useEffect(() => {
        if (isSignUp) {
            setIsFormValid(
                validateEmail(email) &&
                password.length >= 4 &&
                password === confirmPassword &&
                fullName.trim() !== ''
            );
        } else {
            setIsFormValid(
                validateEmail(email) &&
                password.length >= 4
            );
        }
    }, [email, password, confirmPassword, fullName, isSignUp]);

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    const handleAuthentication = async (authFunction, ...args) => {
        try {
            await authFunction(...args);
            showAlert('Successfully authenticated!', 'success');
            navigate('/chat');
        } catch (error) {
            showAlert(`Authentication error: ${error.message}`, 'error');
        }
    };

    const handleGoogleSignIn = () => handleAuthentication(signInWithGoogle);
    const handleAppleSignIn = () => handleAuthentication(signInWithApple);

    const handleEmailAuth = async (e) => {
        e.preventDefault();
        if (isSignUp) {
            if (password !== confirmPassword) {
                showAlert('Passwords don\'t match', 'error');
                return;
            }
            await handleAuthentication(signUpWithEmail, email, password, fullName);
        } else {
            await handleAuthentication(signInWithEmail, email, password);
        }
    };

    return (
        <ThemeProvider>
          <div className="flex items-center justify-center min-h-screen w-screen bg-[rgb(229,229,225)] dark:bg-[rgb(36,36,36)] p-4 font-semibold overflow-auto">
            <div className="w-full max-w-md text-center max-h-screen overflow-y-auto py-8">
              {alert.show && (
                <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 p-4 rounded-full ${alert.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white font-bold`}>
                  {alert.message}
                </div>
              )}
              <div className="flex items-center justify-center mb-4">
                <img 
                  src="/app_icon.png" 
                  alt="Arrow.AI Logo" 
                  className="h-10 w-10 rounded-lg mr-2" 
                />
                <h1 className="text-4xl font-bold dark:text-gray-200">Arrow.AI</h1>
              </div>
              <p className="text-lg mb-8 font-semibold dark:text-gray-300">Access cutting-edge AI from OpenAI, Anthropic, Google, Perplexity, and more (soon).<br></br> Start now for free!</p>
    
              <button 
                onClick={handleGoogleSignIn} 
                className="w-full bg-white text-black border border-gray-300 rounded-full py-2 mb-4 flex items-center justify-center font-bold transition-transform duration-200 hover:scale-105"
              >
                <GoogleLogo />
                <span className="ml-2">Continue with Google</span>
              </button>
    
              <button 
                onClick={handleAppleSignIn} 
                className="w-full bg-white text-black border border-gray-300 rounded-full py-2 mb-4 flex items-center justify-center font-bold transition-transform duration-200 hover:scale-105"
              >
                <AppleLogo />
                <span className="ml-2">Continue with Apple</span>
              </button>
    
              <div className="text-center text-gray-500 my-4 font-bold">or</div>
    
              <form onSubmit={handleEmailAuth} className="w-full">
                {isSignUp && (
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
                  />
                )}
                <input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
                />
                {isSignUp && (
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full mb-4 p-2 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgb(36,36,36)] dark:text-white dark:border-gray-600"
                  />
                )}
                <button 
                  type="submit" 
                  className={`w-full ${isFormValid ? 'bg-gray-900 dark:bg-[rgb(71,150,228)] hover:scale-105' : 'bg-gray-500 cursor-not-allowed'} text-white rounded-full py-2 mb-4 font-bold transition-transform duration-200`}
                  disabled={!isFormValid}
                >
                  {isSignUp ? 'Sign Up' : 'Log In'}
                </button>
              </form>
    
              <button 
                onClick={() => {
                  setIsSignUp(!isSignUp);
                  setIsFormValid(false);
                  setEmail('');
                  setPassword('');
                  setConfirmPassword('');
                  setFullName('');
                }} 
                className="text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 w-full text-center font-bold transition-colors duration-200"
              >
                {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
              </button>
    
              <p className="text-sm text-gray-500 mt-4 text-center font-semibold">
                By continuing, you are agreeing to Arrow.AI's Terms of Service and Privacy Policy.
              </p>
            </div>
          </div>
        </ThemeProvider>
    );
};

export default SignInScreen;