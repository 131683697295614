import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import SignInScreen from './SignInScreen';
import MainChatInterface from './MainChatInterface';
import SubscriptionScreen from './SubscriptionScreen';
import './fonts.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('App component mounted');

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    }, (error) => {
      console.error('Auth error:', error);
      setError(error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/welcome"
          element={
            user ? (
              <Navigate to="/chat" replace />
            ) : (
              <SignInScreen />
            )
          }
        />
        <Route
          path="/"
          element={
            user ? (
              <Navigate to="/chat" replace />
            ) : (
              <SignInScreen />
            )
          }
        />
        <Route
          path="/chat"
          element={
            user ? (
              <MainChatInterface />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/subscribe"
          element={
            user ? (
              <SubscriptionScreen />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;