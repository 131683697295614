// import { ChatModel } from './chatModels';
// import React from 'react';
// import { Menu, PlusSquare } from 'lucide-react';
// import CustomDropdown from './CustomDropdown';
// import { useTheme } from './ThemeContext';

// const MobileToolbar = ({
//   toggleSidebar,
//   onNewChat,
//   chatTitle,
//   model,
//   selectedModel,
//   setSelectedModel,
//   hasSubscription,
// }) => {
//     const { theme, setTheme } = useTheme();

//     const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

//   const buttonStyle = "h-8 w-8 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200";

//   return (
//     <div className="flex items-center justify-between bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] bg-opacity-70 dark:bg-opacity-70 backdrop-blur-md p-4 absolute top-0 left-0 right-0 z-10 h-16 shadow-sm transition-colors duration-200">
//       <button onClick={toggleSidebar} className={buttonStyle}>
//         <Menu size={24} color={isDarkMode ? 'white' : 'black'} />
//       </button>
//       <div className="flex flex-col items-center">
//         <h1 className="text-lg font-semibold text-black dark:text-white truncate max-w-[150px]">
//           {chatTitle}
//         </h1>
//         <CustomDropdown
//           options={Object.values(ChatModel).map(model => ({
//             display: model.name,
//             value: model.modelString,
//             provider: model.provider,
//             isPremium: model.isPremium
//           }))}
//           value={selectedModel}
//           onChange={setSelectedModel}
//           hasSubscription={hasSubscription}
//           isDarkMode={isDarkMode}
//         />
//       </div>
//       <button onClick={onNewChat} className={buttonStyle}>
//       <img
//               src={isDarkMode ? "/square.and.pencil.darkmode.png" : "/square.and.pencil.lightmode.png"}
//               alt="New Chat"
//               className={"h-6 w-auto"}
//             />
//       </button>
//     </div>
//   );
// };

// export default MobileToolbar;
import { ChatModel } from './chatModels';
import React from 'react';
import { Menu } from 'lucide-react';
import CustomDropdown from './CustomDropdown';
import { useTheme } from './ThemeContext';

const MobileToolbar = ({
  toggleSidebar,
  onNewChat,
  chatTitle,
  model,
  selectedModel,
  setSelectedModel,
  hasSubscription,
  hasBasicSubscription,
}) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);
  const buttonStyle = "h-8 w-8 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200";

  const isNewChat = !chatTitle || chatTitle === 'New Chat';

  const getModelDetails = (modelString) => {
    return Object.values(ChatModel).find(m => m.modelString === modelString) ||
      Object.values(ChatModel).find(m => m.name.toLowerCase() === modelString.toLowerCase());
  };
  const modelDetails = getModelDetails(model || selectedModel);
  const displayModelName = modelDetails ? modelDetails.name : 'Unknown Model';
  const modelColor = modelDetails ? modelDetails.color : '#808080';

  const modelOptions = Object.values(ChatModel).map(model => ({
    display: model.name,
    value: model.modelString,
    provider: model.provider,
    isPremium: model.isPremium
  }));

  return (
    <div className="flex items-center justify-between bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] bg-opacity-70 dark:bg-opacity-70 backdrop-blur-md p-4 absolute top-0 left-0 right-0 z-10 h-16 shadow-sm transition-colors duration-200">
      <button onClick={toggleSidebar} className={buttonStyle}>
        <Menu size={24} color={isDarkMode ? 'white' : 'black'} />
      </button>
      <div className="flex flex-col items-center">
        {!isNewChat && (
          <h1 className="text-lg font-semibold text-black dark:text-white truncate max-w-[150px]">
            {chatTitle}
          </h1>
        )}
        {isNewChat ? (
          <CustomDropdown
            options={modelOptions}
            value={selectedModel}
            onChange={setSelectedModel}
            hasSubscription={hasSubscription}
            isDarkMode={isDarkMode}
          />
        ) : (
          <span
            className="px-3 py-1 text-sm rounded-full text-[rgb(250,250,250)] dark:text-[rgb(255,255,255)]"
            style={{
              backgroundColor: modelColor,
              opacity: 1.0,
              transition: 'background-color 0.3s'
            }}
          >
            {displayModelName}
          </span>
        )}
      </div>
      <button onClick={onNewChat} className={buttonStyle}>
        <img
          src={isDarkMode ? "/square.and.pencil.darkmode.png" : "/square.and.pencil.lightmode.png"}
          alt="New Chat"
          className={"h-6 w-auto"}
        />
      </button>
    </div>
  );
};

export default MobileToolbar;