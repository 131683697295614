import React, { useState } from 'react';
import { auth } from './firebase';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from './ThemeContext';
import './SubscriptionScreen.css';

// Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51Q00slApEIymxqdxJNihHzZW4I4gW5AFyn8KBg1GmF8zD5wNMBglrb0yenVIULN2hUT1FlemPhUCpBvT7DUrtfh000C6iBwaSW');

const SubscriptionScreen = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const subscriptionOptions = [
        {
            name: 'Starter Plan',
            priceId: 'price_1Q7eNoApEIymxqdxxCfcgTHe',
            price: '$5.99',
            period: 'week',
            individualCost: '$20.00'
        },
        {
            name: 'Plus Plan',
            priceId: 'price_1Q7eNoApEIymxqdxbgAYtON2',
            price: '$19.99',
            period: 'month',
            individualCost: '$80.00',
            banner: 'Popular Choice!'
        },
        {
            name: 'Premium Plan',
            priceId: 'price_1Q7eNoApEIymxqdx5G8rii2i',
            price: '$204.99',
            period: 'year',
            individualCost: '$960.00',
            banner: 'Best Offer!'
        },
        {
            name: 'Basic Plan',
            priceId: 'price_1QAYXLApEIymxqdxtUbuMPnp',
            price: '$2.99',
            period: 'month',
        },
    ];

    const features = [
        'Cancel anytime',
        'Ad-free experience',
        'Access to all models',
        'Custom themes (coming soon)',
        'Early access to new features and models',
        'Request new features/models',
    ];


    const handleSubscription = async (priceId) => {
        setLoading(true);
        setError(null);
        const user = auth.currentUser;

        try {
            // 1. Create a Stripe customer
            const customerResponse = await fetch('https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/create-stripe-customer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid: user.uid, email: user.email }),
            });
            const customerData = await customerResponse.json();

            if (!customerData.success) {
                throw new Error('Failed to create Stripe customer');
            }

            // 2. Create a Checkout Session
            const sessionResponse = await fetch('https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid: user.uid, priceId }),
            });
            const sessionData = await sessionResponse.json();

            if (!sessionData.sessionId) {
                throw new Error('Failed to create Checkout Session');
            }

            // 3. Redirect to Checkout
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionData.sessionId,
            });

            if (error) {
                throw new Error(error.message);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    const renderPlanCard = (option, index) => {
        const isBasicPlan = option.name === 'Basic Plan';
        
        if (isBasicPlan) {
            return (
                <div key={option.priceId} className="col-span-3 bg-[rgb(214,214,210)] dark:bg-[rgb(36,36,36)] rounded-2xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105">
                    <div className="flex items-center h-full">
                        <div className="w-1/4 h-full bg-gradient-to-r from-blue-500 to-purple-600 p-6 rounded-r-3xl">
                            <h3 className="text-2xl leading-8 font-extrabold text-white tracking-tight">
                                {option.name}
                            </h3>
                            <div className="mt-4 flex items-baseline text-white">
                                <span className="text-5xl font-extrabold tracking-tight">
                                    {option.price}
                                </span>
                                <span className="ml-1 text-xl font-medium">/{option.period}</span>
                            </div>
                        </div>
                        <div className="flex-grow flex items-center justify-center">
                            <p className="text-center text-lg font-medium text-gray-700 dark:text-gray-100">
                                Access to basic models on the web. No ads, cancel anytime! iOS app is free!
                            </p>
                        </div>
                        <div className="w-1/4 p-6 flex items-center justify-end">
                            <button
                                onClick={() => handleSubscription(option.priceId)}
                                disabled={loading}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 border border-transparent rounded-md py-3 px-5 text-base font-medium text-white hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
                            >
                                {loading ? 'Processing...' : `Subscribe ${option.name}`}
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    
        // Layout for other plans (Starter, Plus, Premium)
        return (
            <div 
                key={option.priceId} 
                className="bg-[rgb(214,214,210)] dark:bg-[rgb(36,36,36)] rounded-2xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105 relative"
            >
                {option.banner && (
                    <div className="banner-container">
                        <div className="banner">{option.banner}</div>
                    </div>
                )}
                <div className="px-6 py-8 bg-gradient-to-r from-blue-500 to-purple-600 sm:p-10 sm:pb-6 rounded-t-2xl rounded-b-2xl">
                    <h3 className="text-2xl leading-8 font-extrabold text-white tracking-tight">
                        {option.name}
                    </h3>
                    <div className="mt-4 flex items-baseline text-white">
                        <span className="text-5xl font-extrabold tracking-tight">
                            {option.price}
                        </span>
                        <span className="ml-1 text-xl font-medium">/{option.period}</span>
                    </div>
                    {option.individualCost && (
                        <p className="mt-2 text-sm text-blue-100">
                            Would cost {option.individualCost}/{option.period} individually!
                        </p>
                    )}
                </div>
                <div className="px-6 pt-6 pb-8 bg-[rgb(214,214,210)] dark:bg-[rgb(36,36,36)] sm:p-10 sm:pt-6 rounded-b-2xl">
                    <ul className="space-y-4">
                        {features.map((feature, index) => (
                            <li key={index} className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base text-gray-700 dark:text-gray-100">{feature}</p>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-8">
                        <button
                            onClick={() => handleSubscription(option.priceId)}
                            disabled={loading}
                            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 border border-transparent rounded-md py-3 px-5 text-base font-medium text-white hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
                        >
                            {loading ? 'Processing...' : `Subscribe ${option.name}`}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <ThemeProvider>
        <div className="min-h-screen bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] from-blue-100 to-purple-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-extrabold text-center text-gray-900 dark:text-gray-400 mb-12">
                    Choose Your Subscription Plan
                </h1>
                <div className="mb-8 grid grid-cols-1">
                    {renderPlanCard(subscriptionOptions[3])} {/* Basic Plan */}
                </div>
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    {subscriptionOptions.slice(0, 3).map(renderPlanCard)}
                </div>
                {error && (
                    <p className="mt-8 text-center text-red-600 text-lg">{error}</p>
                )}
            </div>
        </div>
        </ThemeProvider>
    );
};

export default SubscriptionScreen;