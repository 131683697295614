import { ChatModel } from './chatModels';
import React, { useState } from 'react';
import CustomDropdown from './CustomDropdown';
import { Sun, Moon, Monitor } from 'lucide-react';
import { useTheme } from './ThemeContext';

const Toolbar = ({
  toggleSidebar,
  onNewChat,
  chatTitle,
  model,
  isSidebarOpen,
  selectedModel,
  setSelectedModel,
  sidebarWidth,
  hasSubscription,
  hasBasicSubscription
}) => {
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showNewChatTooltip, setShowNewChatTooltip] = useState(false);
  const { theme, setTheme } = useTheme();

  const buttonStyle = "h-8 w-8 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200";
  const imageStyle = "h-5 w-auto";

  const modelOptions = Object.values(ChatModel).map(model => ({
    display: `${model.name}`,
    value: model.modelString,
    provider: model.provider,
    isPremium: model.isPremium
  }));

  const getModelDetails = (modelString) => {
    return Object.values(ChatModel).find(m => m.modelString === modelString) ||
      Object.values(ChatModel).find(m => m.name.toLowerCase() === modelString.toLowerCase());
  };
  const modelDetails = getModelDetails(model || selectedModel);
  const displayModelName = modelDetails ? modelDetails.name : 'Unknown Model';
  const modelColor = modelDetails ? modelDetails.color : '#808080';
  const isNewChat = !chatTitle || chatTitle === 'New Chat';

  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      if (prevTheme === 'light') return 'dark';
      if (prevTheme === 'dark') return 'system';
      return 'light';
    });
  };

  const getThemeIcon = () => {
    switch (theme) {
      case 'light': return <Sun size={16} />;
      case 'dark': return <Moon size={16} />;
      case 'system': return <Monitor size={16} />;
    }
  };

  const themeButtonStyle = `${buttonStyle} ${isDarkMode ? 'text-white' : 'text-gray-800'}`;

  return (
    <div className="flex items-center bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] bg-opacity-70 dark:bg-opacity-70 backdrop-blur-md p-4 absolute top-0 left-0 right-0 z-10 h-16 shadow-sm transition-colors duration-200">
      <div className="flex items-center" style={{ width: isSidebarOpen ? `${sidebarWidth}px` : 'auto' }}>
        <div className="relative">
          <button
            onClick={toggleSidebar}
            onMouseEnter={() => setShowSidebarTooltip(true)}
            onMouseLeave={() => setShowSidebarTooltip(false)}
            className={buttonStyle}
          >
            <img
              src={isDarkMode ? "/sidebar.left.darkmode.png" : "/sidebar.left.lightmode.png"}
              alt={isSidebarOpen ? "Hide sidebar" : "Show sidebar"}
              className={imageStyle}
            />
          </button>
          {showSidebarTooltip && (
            <div className="absolute left-0 mt-2 py-1 px-2 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
              {isSidebarOpen ? "Hide sidebar" : "Show sidebar"}
            </div>
          )}
        </div>
        <div className="relative ml-auto">
          <button
            onClick={onNewChat}
            onMouseEnter={() => setShowNewChatTooltip(true)}
            onMouseLeave={() => setShowNewChatTooltip(false)}
            className={buttonStyle}
          >
            <img
              src={isDarkMode ? "/square.and.pencil.darkmode.png" : "/square.and.pencil.lightmode.png"}
              alt="New Chat"
              className={"h-6 w-auto"}
            />
          </button>
          {showNewChatTooltip && (
            <div className="absolute right-0 mt-2 py-1 px-2 bg-gray-800 text-white text-xs rounded">
              New Chat
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center ml-8">
        {!isNewChat && (
          <h1 className="text-xl font-semibold text-black dark:text-white mr-4">
            {chatTitle}
          </h1>
        )}
        {isNewChat ? (
          <CustomDropdown
            options={modelOptions}
            value={selectedModel}
            onChange={setSelectedModel}
            hasSubscription={hasSubscription}
            isDarkMode={isDarkMode}
          />
        ) : (
          <span
            className="px-3 py-1 text-sm rounded-full text-[rgb(250,250,250)] dark:text-[rgb(255,255,255)]"
            style={{
              backgroundColor: modelColor,
              opacity: 1.0,
              transition: 'background-color 0.3s'
            }}
          >
            {displayModelName}
          </span>
        )}
      </div>
      <div className="flex-grow" />
      <button
        onClick={toggleTheme}
        className={`${themeButtonStyle} ml-2`}
        title={`Current theme: ${theme}`}
      >
        {getThemeIcon()}
      </button>
    </div>
  );
};

export default Toolbar;