import React, { useEffect } from 'react';
import AdSense from 'react-adsense';

const GoogleAd = ({ client, slot, format = 'auto', responsive = 'true' }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <AdSense.Google
      client={client}
      slot={slot}
      style={{ display: 'block' }}
      format={format}
      responsive={responsive}
    />
  );
};

export default GoogleAd;