import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, setDoc, addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseConfig, firestore, auth, storage } from './firebase';
import uuid from 'react-native-uuid';
import { Send, Image as ImageIcon, X} from 'lucide-react';
import { getChatModelByModelString, isGoogle, getMaxTokens, getEndpoint, isOpenAIReasoning, isAnthropic, isImageGeneration } from './chatModels';
import { initializeApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const MessageInput = ({ selectedChat, selectedModel, selectedModelName, onMessageSent, messages = [], updateChatTitle, hasSubscription, hasBasicSubscription }) => {
  const [input, setInput] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const lastUpdateRef = useRef(0);
  const updateIntervalRef = useRef(100);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const [imageBase64, setImageBase64] = useState(null);
  const firebaseApp = initializeApp(firebaseConfig);
  const vertexAI = getVertexAI(firebaseApp);
  const navigate = useNavigate();

  const convertToJPEG = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
            resolve(convertedFile);
          }, 'image/jpeg', 0.8); // Adjust quality as needed (0.8 = 80% quality)
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const fileToGenerativePart = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          inlineData: {
            data: reader.result.split(',')[1],
            mimeType: file.type
          },
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const clearImageState = () => {
    setSelectedImage(null);
    setImagePreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  
  const handleSendMessage = useCallback(async () => {
    if (!input.trim() && !selectedImage) return;
    if (!auth.currentUser?.uid) {
      navigate('/welcome');
      return;
    }
    // if (!hasBasicSubscription && !hasSubscription) {
    //   navigate('/subscribe');
    //   return;
    // }

    let imageUrl = '';
    let imagePart = null;

    const userMessage = {
      id: uuid.v4(),
      text: input,
      role: 'user',
      streaming: false,
      imageUrl: imagePreviewUrl, // Use the local image URL initially
      pending: true,
      localImage: true // Flag to indicate we're using a local image
    };
    
    let chatId = selectedChat?.id;

    try {
      if (!chatId) {
        // Create a new chat document
        const newChatRef = doc(collection(firestore, 'chats'));
        chatId = newChatRef.id;

        const modelName = selectedModelName || 'GPT 4o mini';
        await setDoc(newChatRef, {
          id: chatId,
          owner: auth.currentUser.uid,
          model: modelName,
          topic: 'Untitled Chat',
          lastMessageSent: serverTimestamp(),
        });
      }

      // Immediately update local state to show the message
      onMessageSent(userMessage, chatId);

      // Clear input and image state
      setInput('');
      clearImageState();
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Upload image in the background if present
      if (selectedImage) {
        const imageRef = ref(storage, `chat_images/${auth.currentUser.uid}/${Date.now()}_image.jpg`);
        await uploadBytes(imageRef, selectedImage);
        imageUrl = await getDownloadURL(imageRef);
        imagePart = await fileToGenerativePart(selectedImage);
        
        // Update the message with the uploaded image URL
        userMessage.imageUrl = imageUrl;
        userMessage.localImage = false;
        onMessageSent(userMessage, chatId);
      }

      // Add user message to Firestore in the background
      await addMessageToFirestore(chatId, userMessage);

      // Update the message in local state to remove the pending flag
      userMessage.pending = false;
      onMessageSent(userMessage, chatId);

      const modelDetails = getChatModelByModelString(selectedModel);
      const assistantMessageId = uuid.v4();
      let accumulatedResponse = '';
      const conversationHistory = [...messages, userMessage];

      const maxTokens = getMaxTokens(selectedModel);
      const updateMessage = (text) => {
        const now = Date.now();
        if (now - lastUpdateRef.current >= updateIntervalRef.current) {
          if (!assistantMessage.text) {
            // First update: create and send the assistant message
            assistantMessage.text = text.trim();
            onMessageSent(assistantMessage, chatId);
          } else {
            // Subsequent updates: update the existing message
            assistantMessage.text = text.trim();
            onMessageSent(assistantMessage, chatId);
          }
          lastUpdateRef.current = now;
        }
      };

      // Initialize the assistant message, but don't send it yet
      const assistantMessage = {
        id: assistantMessageId,
        text: '',
        role: 'assistant',
        streaming: true,
      };


      if (isGoogle(modelDetails)) {
        const model = getGenerativeModel(vertexAI, { model: selectedModel });
        const chat = model.startChat({
          history: conversationHistory.map(msg => ({
            role: msg.role === 'user' ? 'user' : 'model',
            parts: [{ text: msg.text }],
          })),
          generationConfig: {
            maxOutputTokens: maxTokens,
          },
        });

        const parts = [{ text: input }];
        if (imagePart) {
          parts.push(imagePart);
        }

        const result = await chat.sendMessageStream(parts);

        for await (const chunk of result.stream) {
          const chunkText = chunk.text();
          accumulatedResponse += chunkText;
          updateMessage(accumulatedResponse);
        }
      } else if (isOpenAIReasoning(modelDetails)) {
        const requestBody = {
          messages: conversationHistory,
          model: selectedModel,
        };

        const authToken = await auth.currentUser.getIdToken();
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        if (data.content) {
          accumulatedResponse = data.content;
          updateMessage(accumulatedResponse);
        } else {
          throw new Error('Unexpected response format');
        }
      } else if (isImageGeneration(modelDetails)) {
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const requestBody = {
          messages: conversationHistory,
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const imageResponse = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (imageResponse.ok) {
          const imageData = await imageResponse.json();
          imageUrl = imageData.image;
          
          assistantMessage.streaming = false;
          assistantMessage.text = imageUrl;

          // Update local state one last time with the complete message
          onMessageSent(assistantMessage, chatId);

          // Add the final assistant message to Firestore
          await addMessageToFirestore(chatId, assistantMessage);
          await generateAndUpdateTopic(imageUrl, chatId);
        } else {
          console.error('Image generation failed');
          // Handle error - perhaps add an error message to the chat
        }
      } else {
        const requestBody = {
          messages: conversationHistory,
          model: selectedModel,
          imageBase64: imageBase64,
          max_tokens: maxTokens
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          for (const line of lines) {
            if (line.startsWith('data:')) {
              const jsonString = line.slice(5).trim();
              if (jsonString === '[DONE]') {
                done = true;
                break;
              }
              try {
                const { content } = JSON.parse(jsonString);
                accumulatedResponse += content;
                updateMessage(accumulatedResponse);
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }
      }
      if (!isImageGeneration(modelDetails)) {
        assistantMessage.streaming = false;
        assistantMessage.text = accumulatedResponse.trim();
        onMessageSent(assistantMessage, chatId);
        await addMessageToFirestore(chatId, assistantMessage);
        await generateAndUpdateTopic(accumulatedResponse.trim(), chatId);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [input, selectedImage, imageBase64, selectedChat, selectedModel, selectedModelName, onMessageSent, messages]);

  const handleImageSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const jpegFile = await convertToJPEG(file);
      setSelectedImage(jpegFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        const base64String = reader.result.split(',')[1];
        setImageBase64(base64String);
      };
      reader.readAsDataURL(jpegFile);
    }
  };

  const handleRemoveImage = () => {
    clearImageState();
  };

  const triggerImageSelect = () => {
    fileInputRef.current.click();
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const addMessageToFirestore = async (chatId, message) => {
    const messagesRef = collection(firestore, 'chats', chatId, 'messages');
    const messageData = {
      id: message.id,
      text: message.text,
      role: message.role,
      createdAt: serverTimestamp(),
    };

    // Only include imageUrl if the message is from the user and an image was uploaded
    if (message.role === 'user' && message.imageUrl) {
      messageData.imageUrl = message.imageUrl;
    }

    if (message.role === 'user' && message.imageBase64) {
      messageData.imageBase64 = message.imageBase64;
    }

    await addDoc(messagesRef, messageData);

    // Update the lastMessageSent field for the chat document
    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      lastMessageSent: serverTimestamp(),
    });
  };

  const generateAndUpdateTopic = async (responseText, chatId) => {
    const lastUserMessage = messages[messages.length - 2]?.text || ''; // Assuming the last user message is second to last in the array
    const lastAssistantMessage = responseText;

    const topicPrompt = `
      Based on the following conversation, generate a concise topic of 4-5 words maximum and 30 letters or less:
      User: ${lastUserMessage}
      AI: ${lastAssistantMessage}
      Topic:
    `;

    const authToken = await auth.currentUser.getIdToken();
    const topicResponse = await fetch('https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/generate-topic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({ prompt: topicPrompt }),
    });

    const result = await topicResponse.json();
    const generatedTopic = result.topic;

    // Update Firestore with the generated topic
    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      topic: generatedTopic,
    });

    updateChatTitle(generatedTopic);
  };


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 300)}px`;
    }
  }, [input]);

  const isInputEmpty = input.trim() === '';

  return (
    <div className="p-4 w-full bg-transparent dark:bg-transparent transition-colors duration-200">
      {imagePreviewUrl && (
        <div className="mb-2 relative inline-block">
          <img src={imagePreviewUrl} alt="Selected" className="max-h-32 rounded-md" />
          <button
            onClick={handleRemoveImage}
            className="absolute top-1 right-1 bg-gray-800 bg-opacity-50 text-white rounded-full p-1 hover:bg-opacity-75 transition-colors duration-200"
          >
            <X size={16} />
          </button>
        </div>
      )}
      <div className="flex items-center relative">
        <textarea
          ref={textareaRef}
          rows="1"
          className="bg-[rgb(209,209,205)] dark:bg-[rgb(36,36,36)] w-full pl-14 pr-12 py-3.5 h-[56px] rounded-[28px] focus:outline-none focus:ring-2 focus:ring-[rgb(199,199,195)] dark:focus:ring-[rgb(70,70,70)] text-gray-800 dark:text-white placeholder-gray-600 dark:placeholder-gray-500 resize-none overflow-y-auto"
          placeholder={`Message ${selectedModelName}${isImageGeneration(getChatModelByModelString(selectedModel)) ? ' (Image prompt)' : ''}`}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            minHeight: '44px',
            maxHeight: '300px',
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
          }}
        />
        <button
          onClick={triggerImageSelect}
          className="absolute left-2.5 bottom-2 p-2.5 rounded-full transition-colors duration-200 bg-gray-200 dark:bg-[rgb(22,22,22)] text-gray-600 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-500"
        >
          <ImageIcon size={16} />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageSelect}
          accept="image/*"
          className="hidden"
        />
        <button
          onClick={handleSendMessage}
          disabled={isInputEmpty && !selectedImage}
          className={`absolute right-2.5 bottom-2 p-2.5 rounded-full transition-colors duration-200 ${
            isInputEmpty && !selectedImage
              ? 'bg-gray-200 dark:bg-[rgb(76,76,76)] text-gray-400 dark:text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
          }`}
        >
          <Send size={16} />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;