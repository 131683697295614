// import React, { useState, useRef, useEffect } from 'react';
// import { ChevronDown, ChevronRight, Brain, Globe, Lock } from 'lucide-react';
// import { AIProvider, ChatModel } from './chatModels';
// import PremiumFeatureAlert from './components/ui/alert';
// import { useTheme } from './ThemeContext';

// const CustomDropdown = ({ options, value, onChange, hasSubscription, onUpgrade }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [openSubmenu, setOpenSubmenu] = useState(null);
//   const [showAlert, setShowAlert] = useState(false);
//   const [lastPremiumAttempt, setLastPremiumAttempt] = useState(null);
//   const dropdownRef = useRef(null);
//   const { theme } = useTheme();

//   const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//         setOpenSubmenu(null);
//       }
//     };
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const toggleDropdown = () => setIsOpen(!isOpen);

//   const handleOptionClick = (option, event) => {
//     event.stopPropagation();
//     if (option.subOptions) {
//       setOpenSubmenu(openSubmenu === option.value ? null : option.value);
//     } else if (option.isPremium && !hasSubscription) {
//       setShowAlert(true);
//       setLastPremiumAttempt(option.value);
//     } else {
//       onChange(option.value);
//       setIsOpen(false);
//       setOpenSubmenu(null);
//     }
//   };

//   const handleAlertClose = () => {
//     setShowAlert(false);
//     setLastPremiumAttempt(null);
//   };

//   const selectedModel = options.find(opt => opt.value === value) || options[0];

//   const getIcon = (option) => {
//     const iconColor = isDarkMode ? 'white' : 'currentColor';
//     switch (option.value) {
//       case 'auto':
//         return <Brain size={20} color={iconColor} />;
//       case 'llama-3.1-sonar-large-128k-online':
//         return <Globe size={20} color={iconColor} />;
//       default:
//         return null;
//     }
//   };

//   const renderOptions = (optionsToRender, level = 0) => {
//     return optionsToRender.map((option, index) => (
//       <li
//         key={index}
//         className="relative"
//       >
//         <div
//           onClick={(e) => handleOptionClick(option, e)}
//           className={`px-4 py-2 cursor-pointer flex items-center justify-between ${
//             option.value === value ? 'bg-blue-500 text-white' : isDarkMode ? 'text-white' : 'text-gray-800'
//           } ${
//             isDarkMode
//               ? 'hover:bg-[rgb(30,30,30)]'
//               : 'hover:bg-[rgb(199,199,195)]'
//           }`}
//         >
//           <div className="flex items-center">
//             {getIcon(option)}
//             <span className="ml-2">{option.display}</span>
//           </div>
//           {option.subOptions && (
//             <ChevronRight
//               size={16}
//               color={isDarkMode ? 'white' : 'currentColor'}
//               className={`transition-transform duration-200 ${
//                 openSubmenu === option.value ? 'transform rotate-90' : ''
//               }`}
//             />
//           )}
//         </div>
//         {option.subOptions && openSubmenu === option.value && (
//           <ul className={`absolute top-0 left-full mt-0 ml-0.5 border rounded-md shadow-lg ${
//             isDarkMode
//               ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
//               : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
//           }`}
//           style={{ minWidth: '200px' }}>
//             {renderOptions(option.subOptions, level + 1)}
//           </ul>
//         )}
//       </li>
//     ));
//   };

//   const groupedOptions = [
//     options.find(o => o.value === 'auto'),
//     options.find(o => o.value === 'llama-3.1-sonar-large-128k-online'),
//     {
//       display: 'OpenAI',
//       value: 'OPENAI',
//       subOptions: options.filter(o => (o.provider === AIProvider.OPENAI || o.provider === AIProvider.OPENAI_REASONING))
//     },
//     {
//       display: 'Anthropic',
//       value: 'ANTHROPIC',
//       subOptions: options.filter(o => o.provider === AIProvider.ANTHROPIC)
//     },
//     {
//       display: 'Google',
//       value: 'GOOGLE',
//       subOptions: options.filter(o => o.provider === AIProvider.GOOGLE)
//     },
//   ].filter(Boolean);

//   return (
//     <div className="relative inline-block w-full" ref={dropdownRef}>
//       <button
//         onClick={toggleDropdown}
//         className={`w-full px-4 py-2 text-left rounded-md flex items-center justify-between transition-colors duration-150 ${
//           isDarkMode
//             ? 'bg-[rgb(36,36,36)] text-white hover:bg-[rgb(29,29,29)]'
//             : 'bg-[rgb(219,219,215)] text-gray-800 hover:bg-[rgb(209,209,205)]'
//         }`}
//       >
//         <div className="flex items-center flex-grow">
//           {getIcon(selectedModel)}
//           <span className="text-lg font-semibold ml-2 mr-2">{selectedModel.display}</span>
//         </div>
//         <ChevronDown 
//           className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
//           color={isDarkMode ? 'white' : 'black'}
//           size={24}
//         />
//       </button>
//       {isOpen && (
//         <ul className={`absolute left-0 right-0 mt-1 border rounded-md shadow-lg z-10 ${
//           isDarkMode
//             ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
//             : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
//         }`}>
//           {renderOptions(groupedOptions)}
//         </ul>
//       )}
//       {showAlert && (
//         <PremiumFeatureAlert
//           primaryText="Premium Feature"
//           subText="This model is only available to premium subscribers. Upgrade now to access advanced AI capabilities."
//           onSubscribe={onUpgrade}
//           onClose={handleAlertClose}
//           isDarkMode={isDarkMode}
//         />
//       )}
//     </div>
//   );
// };

// export default CustomDropdown;

import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { AIProvider, ChatModel } from './chatModels';
import PremiumFeatureAlert from './components/ui/alert';
import { useTheme } from './ThemeContext';

const CustomDropdown = ({ options, value, onChange, hasSubscription, onUpgrade }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [lastPremiumAttempt, setLastPremiumAttempt] = useState(null);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const modelOptions = Object.values(ChatModel).map(model => ({
    display: model.name,
    value: model.modelString,
    provider: model.provider,
    isPremium: model.isPremium,
    subtitle: model.subtitle,
    color: model.color,
    symbol: model.symbol
  }));

  const getSymbolImage = (symbolName) => {
    const mode = isDarkMode ? 'dark' : 'light';
    return `/symbols/${symbolName}.${mode}.png`;
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setOpenSubmenu(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option, event) => {
    event.stopPropagation();
    if (option.subOptions) {
      setOpenSubmenu(openSubmenu === option.value ? null : option.value);
    } else if (option.isPremium && !hasSubscription) {
      setShowAlert(true);
      setLastPremiumAttempt(option.value);
    } else {
      onChange(option.value);
      setIsOpen(false);
      setOpenSubmenu(null);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setLastPremiumAttempt(null);
  };


  const getSelectedModel = () => {
    const findModel = (opts) => opts.find(opt => opt.value === value);
    
    let selectedOption = findModel(modelOptions);
    if (selectedOption) return selectedOption;
  
    // Search in grouped options
    for (const option of groupedOptions) {
      if (option.subOptions) {
        selectedOption = findModel(option.subOptions);
        if (selectedOption) return selectedOption;
      }
    }
  
    return modelOptions[0]; // Default to first option if not found
  };

  const selectedModel = getSelectedModel();

  const getIcon = (option) => {
    if (option && option.symbol) {
      return (
        <img 
          src={getSymbolImage(option.symbol)} 
          alt={option.display} 
          className="w-5 h-5 object-contain"
        />
      );
    }
    return null;
  };

  const renderOptions = (optionsToRender, level = 0) => {
    return optionsToRender.map((option, index) => (
      <li
        key={index}
        className="relative"
      >
        <div
          onClick={(e) => handleOptionClick(option, e)}
          className={`px-4 py-2 cursor-pointer flex items-center justify-between ${
            option.value === value ? 'bg-blue-500 text-white' : isDarkMode ? 'text-white' : 'text-gray-800'
          } ${
            isDarkMode
              ? 'hover:bg-[rgb(30,30,30)]'
              : 'hover:bg-[rgb(199,199,195)]'
          }`}
        >
          <div className="flex items-center">
            <div className="flex-shrink-0 w-6 h-6 mr-3 flex items-center justify-center">
              {option.symbol && (
                <img 
                  src={getSymbolImage(option.symbol)} 
                  alt={option.display} 
                  className="w-5 h-5 object-contain"
                />
              )}
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">{option.display}</span>
              {option.subtitle && (
                <span className="text-xs text-gray-500 dark:text-gray-400">{option.subtitle}</span>
              )}
            </div>
          </div>
          {option.subOptions && (
            <ChevronRight
              size={16}
              color={isDarkMode ? 'white' : 'currentColor'}
              className={`transition-transform duration-200 ${
                openSubmenu === option.value ? 'transform rotate-90' : ''
              }`}
            />
          )}
        </div>
        {option.subOptions && openSubmenu === option.value && (
          <ul className={`absolute top-0 left-full mt-0 ml-0.5 border rounded-md shadow-lg ${
            isDarkMode
              ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
              : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
          }`}
          style={{ minWidth: '250px' }}>
            {renderOptions(option.subOptions, level + 1)}
          </ul>
        )}
      </li>
    ));
  };

  const groupedOptions = [
    modelOptions.find(o => o.value === ChatModel.AUTO_SELECTION.modelString),
    modelOptions.find(o => o.value === ChatModel.PERPLEXITY.modelString),
    {
      display: 'OpenAI',
      value: 'OPENAI',
      subtitle: 'ChatGPT models',
      subOptions: modelOptions.filter(o => o.provider === AIProvider.OPENAI || o.provider === AIProvider.OPENAI_REASONING || o.provider === AIProvider.IMAGE_GENERATION)
    },
    {
      display: 'Anthropic',
      value: 'ANTHROPIC',
      subtitle: 'Claude models',
      subOptions: modelOptions.filter(o => o.provider === AIProvider.ANTHROPIC)
    },
    {
      display: 'Google',
      value: 'GOOGLE',
      subtitle: 'Gemini models',
      subOptions: modelOptions.filter(o => o.provider === AIProvider.GOOGLE)
    },
  ].filter(Boolean);

  return (
    <div className="relative inline-block w-full" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`w-full px-4 py-2 text-left rounded-md flex items-center justify-between transition-colors duration-150 ${
          isDarkMode
            ? 'bg-[rgb(36,36,36)] text-white hover:bg-[rgb(29,29,29)]'
            : 'bg-[rgb(219,219,215)] text-gray-800 hover:bg-[rgb(209,209,205)]'
        }`}
      >
        <div className="flex items-center flex-grow">
        <div className="flex-shrink-0 w-6 h-6 mr-3 flex items-center justify-center">
            {getIcon(selectedModel)}
          </div>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{selectedModel.display}</span>
          </div>
        </div>
        <ChevronDown 
          className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
          color={isDarkMode ? 'white' : 'black'}
          size={24}
        />
      </button>
      {isOpen && (
        <ul className={`absolute left-0 right-0 mt-1 border rounded-md shadow-lg z-10 ${
          isDarkMode
            ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
            : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
        }`}>
          {renderOptions(groupedOptions)}
        </ul>
      )}
      {showAlert && (
        <PremiumFeatureAlert
          primaryText="Premium Feature"
          subText="This model is only available to premium subscribers. Upgrade now to access advanced AI capabilities."
          onSubscribe={onUpgrade}
          onClose={handleAlertClose}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default CustomDropdown;