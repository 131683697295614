import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, database } from './firebase';
import { collection, query, where, onSnapshot, orderBy, doc, setDoc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { ref, onValue, set } from 'firebase/database';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import MessageInput from './MessageInput';
import Settings from './Settings';
import { ChatModel, getChatModelByName } from './chatModels';
import Toolbar from './Toolbar';
import { ThemeProvider } from './ThemeContext';
import GoogleAd from './GoogleAd';
import { Menu, X } from 'lucide-react'; // Import icons for mobile menu
import MobileToolbar from './MobileToolbar';

const MainChatInterface = () => {
  const [chatTitle, setChatTitle] = useState('New Chat');
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedModel, setSelectedModel] = useState(Object.values(ChatModel)[0]?.modelString || 'gpt-4o-mini');
  const [selectedModelName, setSelectedModelName] = useState(Object.values(ChatModel)[0]?.name || 'GPT 4o mini');
  const [currentChatModel, setCurrentChatModel] = useState(null);
  const [sidebarWidth, setSidebarWidth] = useState(290); // Adjust this to match your sidebar width
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasBasicSubscription, setHasBasicSubscription] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();
  const firstRun = useRef(true);

  const checkSubscription = useCallback(async (uid) => {
    try {
      const revenueCatEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-revenuecat';
      const stripeEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-stripe';
      const specialUsersEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-special-users';
      const stripeBasicEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-stripe-basic';

      const fetchSubscription = async (endpoint) => {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        return response.json();
      };

      const [revenueCatData, stripeData, specialData, stripeBasicData] = await Promise.all([
        fetchSubscription(revenueCatEndpoint),
        fetchSubscription(stripeEndpoint),
        fetchSubscription(specialUsersEndpoint),
        fetchSubscription(stripeBasicEndpoint)
      ]);
      const hasActiveSubscription = revenueCatData.hasSubscription || stripeData.hasSubscription || specialData.hasSubscription;
      const hasBasicSubscription = stripeBasicData.hasSubscription;

      setHasSubscription(hasActiveSubscription);
      setHasBasicSubscription(hasBasicSubscription);
    } catch (error) {
      console.error('Error checking subscription:', error);
      // Optionally, you might want to set hasSubscription to false in case of an error
      // setHasSubscription(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      setIsSidebarOpen(!newIsMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const user = auth.currentUser;
    if (user && firstRun.current) {
      firstRun.current = false;
      checkSubscription(user.uid);
    }
  }, [checkSubscription]);


  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
      return;
    }
    const chatsRef = collection(firestore, 'chats');
    const q = query(
      chatsRef,
      where('owner', '==', user.uid),
      orderBy('lastMessageSent', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedChats = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const lastMessageSent = data.lastMessageSent ? data.lastMessageSent.toDate() : new Date();
        return {
          id: doc.id,
          ...data,
          lastMessageTimeAgo: getTimeAgo(lastMessageSent)
        };
      });
      setChats(fetchedChats);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching chats:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (selectedChat) {
      const chatModel = getChatModelByName(selectedChat.model);
      setCurrentChatModel(chatModel);
      setSelectedModel(chatModel.modelString);
      setSelectedModelName(chatModel.name);
    } else {
      setCurrentChatModel(null);
      // Reset to the default model when no chat is selected
      const defaultModel = Object.values(ChatModel)[0];
      setSelectedModel(defaultModel.modelString);
      setSelectedModelName(defaultModel.name);
    }
  }, [selectedChat]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUserName(userData.fullName || '');
          setUserEmail(userData.email || '');
        }
      });
    }
  }, []);

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const timeUnits = [
      { value: 31536000, unit: 'year' },
      { value: 2592000, unit: 'month' },
      { value: 86400, unit: 'day' },
      { value: 3600, unit: 'hour' },
      { value: 60, unit: 'minute' },
      { value: 1, unit: 'second' },
    ];

    for (const { value, unit } of timeUnits) {
      const difference = Math.floor(diffInSeconds / value);
      if (difference >= 1) {
        return `${difference} ${unit}${difference > 1 ? 's' : ''} ago`;
      }
    }
    return 'Just now';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChatSelect = (chatId) => {
    const selected = chats.find(chat => chat.id === chatId);
    setSelectedChat(selected);
    setChatTitle(selected.topic || 'New Chat');
    setShowSettings(false);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
    // Fetch messages for the selected chat from Firebase
    const messagesRef = collection(firestore, 'chats', chatId, 'messages');
    const q = query(messagesRef, orderBy('createdAt', 'asc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: data.UUID,
          text: data.text,
          role: data.role,
          imageUrl: data.imageUrl || null, // Use null if imageUrl doesn't exist
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        };
      });

      setMessages((prevMessages) => ({
        ...prevMessages,
        [chatId]: fetchedMessages,
      }));
    });

    return unsubscribe;
  };

  const handleNewChat = () => {
    setSelectedChat(null);
    setSelectedModel(Object.values(ChatModel)[0]?.modelString || 'gpt-4o-mini');
    setMessages({ new: [] });
    setChatTitle('New Chat');
    setShowSettings(false);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const updateChatTitle = useCallback((newTitle) => {
    setChatTitle(newTitle);
  }, []);

  const handleModelChange = (selectedModelString) => {
    const selectedModelObject = Object.values(ChatModel).find(model => model.modelString === selectedModelString);

    setSelectedModel(selectedModelString);
    setSelectedModelName(selectedModelObject?.name || 'Unknown Model');
  };

  const handleMessageSent = useCallback(async (message, chatId) => {
    setMessages((prevMessages) => {
      const currentMessages = prevMessages[chatId] || [];

      // Find the index of the message with the same ID
      const existingIndex = currentMessages.findIndex(msg => msg.id === message.id);

      if (existingIndex !== -1) {
        // If the message exists, update it
        const updatedMessages = [...currentMessages];
        updatedMessages[existingIndex] = message;
        return {
          ...prevMessages,
          [chatId]: updatedMessages,
        };
      } else {
        // If it's a new message, append it
        const lastMessage = currentMessages[currentMessages.length - 1];
        if (lastMessage && lastMessage.text === message.text && lastMessage.role === message.role) {
          // It's a duplicate, don't add it
          return prevMessages;
        }
        // It's a new unique message, append it
        return {
          ...prevMessages,
          [chatId]: [...currentMessages, message],
        };

      }
    });

    // If this is a new chat, update the selectedChat state and chats list
    if (!selectedChat || selectedChat.id !== chatId) {
      const chatDoc = await getDoc(doc(firestore, 'chats', chatId));
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        const lastMessageSent = chatData.lastMessageSent ? chatData.lastMessageSent.toDate() : new Date();
        const newChat = {
          id: chatId,
          ...chatData,
          lastMessageTimeAgo: getTimeAgo(lastMessageSent),
        };
        setSelectedChat(newChat);
        setChats((prevChats) => [newChat, ...prevChats.filter(chat => chat.id !== chatId)]);
      }
    }
  }, [selectedChat, getTimeAgo]);

  const handleUpgrade = () => {
    navigate('/subscribe');
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const handleBackToChat = () => {
    setShowSettings(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <ThemeProvider>
        {isMobile ? (
          // Mobile layout
          <div className="flex bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] transition-colors duration-200">
          <div className="flex flex-col h-full w-full">
            {/* Fixed MobileToolbar at the top */}
            <div className="fixed top-0 left-0 right-0 z-50">
              <MobileToolbar
                toggleSidebar={toggleSidebar}
                onNewChat={handleNewChat}
                chatTitle={chatTitle}
                model={selectedChat ? selectedChat.model : selectedModel}
                selectedModel={selectedModel}
                setSelectedModel={handleModelChange}
                hasSubscription={hasSubscription}
                hasBasicSubscription={hasBasicSubscription}
              />
            </div>

            {/* ChatWindow between toolbar and input */}
            <div className="flex-grow overflow-hidden pt-16 pb-20"> 
              <div className="h-full overflow-y-auto">
                {showSettings ? (
                  <Settings
                    onBackToChat={handleBackToChat}
                    userName={userName}
                    userEmail={userEmail}
                    hasSubscription={hasSubscription}
                    onLogout={handleLogout}
                  />
                ) : (
                  <ChatWindow
                    selectedChat={selectedChat}
                    messages={messages[selectedChat?.id] || []}
                    selectedModel={selectedModel}
                  />
                )}
              </div>
            </div>

            {/* Fixed MessageInput at the bottom */}
            {!showSettings && (
              <div className="fixed bottom-0 left-0 right-0 bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] z-40">
                <MessageInput
                  selectedChat={selectedChat}
                  selectedModel={selectedModel}
                  selectedModelName={selectedChat ? selectedChat.model : selectedModelName}
                  onMessageSent={handleMessageSent}
                  messages={messages[selectedChat?.id] || []}
                  updateChatTitle={updateChatTitle}
                  hasSubscription={hasSubscription}
                  hasBasicSubscription={hasBasicSubscription}
                />
              </div>
            )}

            {/* Sidebar overlay */}
            {isSidebarOpen && (
              <>
                <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={toggleSidebar}></div>
                <div className="fixed inset-y-0 left-0 w-[320px] z-40 mt-16"> {/* Adjust mt-16 based on your toolbar height */}
                  <ChatSidebar
                    isOpen={isSidebarOpen}
                    chats={chats}
                    onChatSelect={handleChatSelect}
                    selectedChatId={selectedChat?.id}
                    hasSubscription={hasSubscription}
                    hasBasicSubscription={hasBasicSubscription}
                    onUpgrade={handleUpgrade}
                    userName={userName}
                    userEmail={userEmail}
                    onSettingsClick={handleSettingsClick}
                    isMobile={isMobile}
                  />
                </div>
              </>
            )}
          </div>
          </div>
        ) : (
          // Desktop layout
          <div className="flex h-screen bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] transition-colors duration-200">
          <>
            {isSidebarOpen && (
              <div className="flex-shrink-0" style={{ width: '320px' }}>
                <ChatSidebar
                  isOpen={isSidebarOpen}
                  chats={chats}
                  onChatSelect={handleChatSelect}
                  selectedChatId={selectedChat?.id}
                  hasSubscription={hasSubscription}
                  hasBasicSubscription={hasBasicSubscription}
                  onUpgrade={handleUpgrade}
                  userName={userName}
                  userEmail={userEmail}
                  onSettingsClick={handleSettingsClick}
                  isMobile={isMobile}
                />
              </div>
            )}
            <div className="flex flex-col flex-grow min-w-0">
              <Toolbar
                toggleSidebar={toggleSidebar}
                onNewChat={handleNewChat}
                chatTitle={chatTitle}
                model={selectedChat ? selectedChat.model : selectedModel}
                isSidebarOpen={isSidebarOpen}
                selectedModel={selectedModel}
                setSelectedModel={handleModelChange}
                sidebarWidth={isSidebarOpen ? 302 : 0}
                hasSubscription={hasSubscription}
                hasBasicSubscription={hasBasicSubscription}
                onUpgrade={handleUpgrade}
              />
              <div className="flex flex-grow overflow-hidden pt-16">
                {hasSubscription ? (
                  <div className="flex flex-col w-full min-w-0 px-4">
                    {showSettings ? (
                      <Settings
                        onBackToChat={handleBackToChat}
                        userName={userName}
                        userEmail={userEmail}
                        hasSubscription={hasSubscription}
                        onLogout={handleLogout}
                      />
                    ) : (
                      <>
                        <div className="flex-grow overflow-y-auto h-[calc(100vh-200px)]">
                          <div className="w-full max-w-6xl mx-auto h-full">
                            <ChatWindow
                              selectedChat={selectedChat}
                              messages={messages[selectedChat?.id] || []}
                              selectedModel={selectedModel}
                            />
                          </div>
                        </div>
                        <div className="flex-shrink-0 flex justify-center">
                          <div className="w-[80%]">
                            <MessageInput
                              selectedChat={selectedChat}
                              selectedModel={selectedModel}
                              selectedModelName={selectedChat ? selectedChat.model : selectedModelName}
                              onMessageSent={handleMessageSent}
                              messages={messages[selectedChat?.id] || []}
                              updateChatTitle={updateChatTitle}
                              hasSubscription={hasSubscription}
                              hasBasicSubscription={hasBasicSubscription}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col w-[100%] min-w-0">
                      {showSettings ? (
                        <Settings
                          onBackToChat={handleBackToChat}
                          userName={userName}
                          userEmail={userEmail}
                          hasSubscription={hasSubscription}
                          onLogout={handleLogout}
                        />
                      ) : (
                        <>
                          <div className="flex-grow overflow-y-auto">
                            <ChatWindow
                              selectedChat={selectedChat}
                              messages={messages[selectedChat?.id] || []}
                              selectedModel={selectedModel}
                            />
                          </div>
                          <div className="flex-shrink-0 flex justify-center">
                            <div className="w-[80%]">
                              <MessageInput
                                selectedChat={selectedChat}
                                selectedModel={selectedModel}
                                selectedModelName={selectedChat ? selectedChat.model : selectedModelName}
                                onMessageSent={handleMessageSent}
                                messages={messages[selectedChat?.id] || []}
                                updateChatTitle={updateChatTitle}
                                hasSubscription={hasSubscription}
                                hasBasicSubscription={hasBasicSubscription}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
          </div>
        )}
      
    </ThemeProvider>
  );
};

export default MainChatInterface;
